import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import ChallengeWinnerTemplate from "app/templates/challenge-winner";

const ChallengeWinnerPage = ({ data, ...props }) => (
  <ChallengeWinnerTemplate {..._get(data, "challengeWinnersJson")} {...props} />
);

export default ChallengeWinnerPage;

export const query = graphql`
  query ($id: String) {
    challengeWinnersJson(id: { eq: $id }) {
      ...ChallengeWinner
    }
  }
`;
