import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import SiteData from "app/content/_site-settings.json";

import { ArticleBody } from "app/components/article";
import { btnField } from "app/components/btn";
import Banner from "app/components/banner";
import ChallengeCardList from "app/components/challenges-card-list";
import { finalistsField } from "app/components/finalists";
import { winnersField } from "app/components/winner-tile";
import GetStarted from "app/components/get-started";
import Layout from "app/components/layout";
import SEOMeta, { seoField } from "app/components/seo";
import SponsorBlock from "app/components/sponsor-block";
import { VideoPlayer } from "app/components/video-header";

import * as sharedStyles from "app/styles/shared";

import * as VideoHeaderStyles from "app/components/video-header.styles";

const S = {
  ...sharedStyles,
  ...VideoHeaderStyles,
};

const getPlace = (index) => {
  // eslint-disable-next-line default-case
  switch(index) {
    case 0:
      return 'First';
    case 1:
      return 'Second';
    case 2:
      return 'Third';
    case 3:
      return 'Fourth';
    case 4:
      return 'Fifth';
    case 5:
      return 'Sixth';
  }
}

export const getPageTitle = ({ title, sdg }) =>
  `${title}, SDG ${sdg.frontmatter.sdg_id}`;

const ChallengeWinner = ({
  color,
  html,
  year,
  resources,
  slug,
  sponsor,
  summary,
  title,
  testimonial,
  videoImage,
  winnerEntries,
  finalistEntries,
  videoUrl,
  ...props
}) => {
  const challenges_banner = _get(SiteData, "challenges_banner");
  const sdgId = _get(props, "sdg.sdg_id")
    ? `, SDG ${_get(props, "sdg.sdg_id")}`
    : ``;

  return (
    <Layout pageTitle={`${title}${sdgId}`}>
      <SEOMeta title={title} {..._get(props, "seo")} />
      <article>
        <Banner
          {...challenges_banner}
          text={_get(challenges_banner, "text", "").replace(
            "%CHALLENGE_NAME%",
            title
          )}
        />
        <S.InnerContainer>
          <h1 css={S.h1}>Congratulations to Winners and Finalists in the {year} Challenge Season!</h1>
          <p css={S.bodyTxt}>Here are the top competitors in the {year}-{year - 2000 + 1} academic year NFTE World Series of Innovation.</p>
          <h2 css={S.h2}>{title}</h2>
          <p css={S.bodyTxt}><strong>The Challenge: </strong>{summary}</p>
        </S.InnerContainer>
        <S.Container>
          <S.VHImageBox maxWidth={960}>
            <VideoPlayer video={_get(winnerEntries, "[0].url")} />
          </S.VHImageBox>
        </S.Container>
        <S.InnerContainer>
          <ArticleBody html={html} />
        </S.InnerContainer>
        <SponsorBlock {...sponsor} />
        {
          <S.InnerContainer>
            <h3>Winners</h3>
            <ul>
            {winnerEntries && ( winnerEntries.map((data, idx) => (
                <li css={S.bodyTxt}><strong>{`${getPlace(idx)} Place: `}</strong> {`${data.name}, ${data.text}`} {
                  data.url && <S.A href={data.url} target="_blank">See their video here.</S.A>
                }</li>
            )))}
            </ul>
          </S.InnerContainer>
        }
        {
          <S.InnerContainer>
            <h3>Finalists</h3>
            <ul>
            {finalistEntries && ( finalistEntries.map((data, idx) => (
                <li css={S.bodyTxt}>{`${data.title}, ${data.text}`} {
                  data.url && <S.A href={data.url} target="_blank">See their video here.</S.A>
                }</li>
            )))}
            </ul>
          </S.InnerContainer>
        }
        <GetStarted
          btn={_get(SiteData, "challenge_cta.btn")}
          title={
            _get(props, "ctaTitle") || _get(SiteData, "challenge_cta.title")
          }
        />
        <ChallengeCardList title={"More Challenges"} excluded={[slug]} />
      </article>
    </Layout>
  );
};

export default ChallengeWinner;

export const challengeWinnerFragment = graphql`
  fragment ChallengeWinner on ChallengeWinnersJson {
    title
    slug
    year
    summary
    cardBack
    body
    videoUrl
    videoImage {
      childImageSharp {
        gatsbyImageData(width: 640, height: 360)
      }
    }
    cardImage {
      childImageSharp {
        gatsbyImageData(width: 300, height: 360)
      }
    }
    sdg {
      ...SDG
      videoImage: coverImage {
        childImageSharp {
          gatsbyImageData(width: 640, height: 360)
        }
      }
    }
    winnerEntries {
      name
      text
      url
    }
    finalistEntries {
      title
      text
      url
    }
  }
`;

export const challegeCTAField = {
  label: "Challenge CTA Banner",
  name: "challenge_cta",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    btnField,
  ],
};

export const challegeWinnerFields = [
  {
    label: "Template",
    name: "template",
    widget: "hidden",
    default: "challenge-winner",
  },
  seoField,
  {
    label: "SDG",
    name: "sdg",
    multiple: false,
    widget: "relation",
    collection: "sdgs",
    searchFields: ["sdg_name", "sdg_id"],
    valueField: "sdg_id",
    displayFields: ["sdg_id", "sdg_name"],
  },
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Slug",
    name: "slug",
    widget: "string",
  },
  {
    label: "Year",
    name: "year",
    widget: "string",
  },
  {
    label: "Summary",
    name: "summary",
    widget: "text",
    required: false,
  },
  {
    label: "Card Back",
    name: "cardBack",
    widget: "text",
    required: false,
  },
  {
    label: "Challenge Video",
    name: "videoUrl",
    widget: "string",
    required: false,
  },
  {
    label: "Challenge Image",
    name: "videoImage",
    widget: "image",
    required: false,
  },
  {
    label: "Challenge Card Image",
    name: "cardImage",
    widget: "image",
    required: false,
    hint: "This will overide the challenge SDG image",
  },
  // sponsorField,
  // resourcesField,
  // testimonialField,
  // {
  //   ...finalistsField,
  //   label: "Winners",
  //   name: "winnerEntries",
  // },
  winnersField,
  finalistsField,
  {
    label: "Content",
    name: "body",
    widget: "markdown",
    required: false,
  },
  {
    label: "Challenge Title",
    name: "ctaTitle",
    widget: "string",
    hint: `Leave blank to use the default text: "${_get(
      SiteData,
      "challenge_cta.title"
    )}"`,
    required: false,
  },
];
